import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ExternalLink , AlertTriangle, CheckCircle, Layout} from "lucide-react";
import {
  doc,
  getDoc,
  updateDoc,
  collection,
  addDoc,
  serverTimestamp,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { db } from "./firebase-config";
import logo from "./media/lisualpro.png";

const NotificationPage = () => {
  const { userid } = useParams();
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [loading, setLoading] = useState(true);
  const [notificationStates, setNotificationStates] = useState({});
  const [projectName, setProjectName] = useState("");
  const [identifier, setIdentifier] = useState("");

  useEffect(() => {
    const fetchUserData = async () => {
      if (userid) {
        try {
          const userDoc = await getDoc(doc(db, "notificaciones", userid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setUserName(userData.name || "Usuario");
            setUserEmail(userData.email || "");
            setUserPhone(userData.phoneNumber || "");
            setIdentifier(userData.identifier || "");
            setNotificationStates({
              Bienvenida: userData.Bienvenida || false,
              CamaraApagada: userData.CamaraApagada || false,
              CamaraTorcida: userData.CamaraTorcida || false,
              CamaraVibra: userData.CamaraVibra || false,
              CobroAtrasado1: userData.CobroAtrasado1 || false,
              CobroAtrasado2: userData.CobroAtrasado2 || false,
              CobroAtrasado3: userData.CobroAtrasado3 || false,
              LenteSucio: userData.LenteSucio || false,
              PostResolucion: userData.PostResolucion || false,
              RecomendacionCamara: userData.RecomendacionCamara || false,
              RecordatorioReunion: userData.RecordatorioReunion || false,
              SolicitarFeedback: userData.SolicitarFeedback || false,
              UltimoAviso: userData.UltimoAviso || false,
              TodaviaNoInstalaron: userData.TodaviaNoInstalaron || false,
            });
            // Fetch project information
            const projectsCollection = collection(db, "usuarios");
            console.log(userData.identifier)
            const q = query(projectsCollection, where("identifier", "==", userData.identifier));
            const projectSnapshot = await getDocs(q);

            if (!projectSnapshot.empty) {
              const projectDoc = projectSnapshot.docs[0];
              setProjectName(projectDoc.data().Name || "Proyecto sin nombre");
            }
          }
        } catch (error) {
          console.error("Error fetching data:", error);
          toast.error("Error al cargar los datos del proyecto", {
            position: "top-right",
            autoClose: 3000,
          });
        }
      }
      setLoading(false);
    };

    fetchUserData();
  }, [userid]);

  const logNotificationAction = async (notificationType) => {
    try {
      await addDoc(collection(db, "notificationLog"), {
        type: notificationType,
        user: userName,
        email: userEmail,
        phoneNumber: userPhone,
        timestamp: serverTimestamp(),
        userId: userid,
        action: "dismissed",
        status: "read",
      });
    } catch (error) {
      console.error("Error logging notification action:", error);
    }
  };

  const handleDismissNotification = async (notificationKey) => {
    try {
      // Update local state
      setNotificationStates((prev) => ({
        ...prev,
        [notificationKey]: false,
      }));

      // Update Firestore
      const userRef = doc(db, "notificaciones", userid);
      await updateDoc(userRef, {
        [notificationKey]: false,
      });

      // Log the notification action
      await logNotificationAction(notificationKey);

      // Show success toast
      toast.success("✅ Notificación marcada como recibida", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } catch (error) {
      console.error("Error updating notification state:", error);
      // Revert local state if update fails
      setNotificationStates((prev) => ({
        ...prev,
        [notificationKey]: true,
      }));
      // Show error toast
      toast.error("❌ Error al actualizar la notificación", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const formatDescription = (text) => {
    return text
      .split("\n")
      .map((line, i) => {
        // Handle bold text (**text**)
        const formattedLine = line.replace(
          /\*\*(.*?)\*\*/g,
          '<strong class="font-bold">$1</strong>'
        );

        // Return line with formatting
        return (
          <span key={i} dangerouslySetInnerHTML={{ __html: formattedLine }} />
        );
      })
      .reduce((acc, curr, i, arr) => {
        // Add line breaks between elements
        return [
          ...acc,
          curr,
          i < arr.length - 1 ? <br key={`br-${i}`} /> : null,
        ];
      }, []);
  };

  const notifications = [
    {
      key: "CamaraApagada",
      title: "⚠️ Alerta: Cámara Inactiva",
      description: `Hola, detectamos que tu cámara esta inactiva hace mas de 72 horas, desde Lisual lo único que podemos hacer es pedirte seguir este procedimiento y darte asistencia remota, exclusivamente, NO es nuestra responsabilidad esta situación.

1- Revisá que la cámara este bien conectada y que no se encuentre dañado el cable. Si no estas en obra, preguntale a alguna persona que revise.
2- Reinicia la energía en obra
3- Fijate que el internet funcione bien o el sistema 4G correctamente instalado.
4- Contactá a nuestro área de soporte al +54911695723655
5- Recordá que todos los jueves a las 15:00 horas (GMT-3) en este link podes tener una reunión sincrónica con nuestro Director de Operaciones: https://meet.google.com/wgk-eirr-sio

✅ Apenas tu cámara vuelva a funcionar, ya no te llegarán mas mensajes como estos.`,
      icon: <span className="text-2xl">📷</span>,
      buttonText: "Contactar Soporte",
      buttonLink: "https://wa.me/5491169572365",
    },
    {
      key: "CamaraTorcida",
      title: "⚠️ Alerta: Cámara Desnivelada",
      description: `Hola, detectamos que tu **cámara esta torcida o no nivelada al 100%.** Queremos asegurarnos que tu timelapse quede perfecto y por ello te enviamos este mensaje.

La cámara tiene una distorsión de ojo de pez leve, por favor **buscá nivelar la cámara para que tanto a la izquierda como a la derecha existá el mismo espacio de cielo**.

Hacelo cuanto antes para evitar que pasen dias con la cámara de esta manera.`,
      icon: <span className="text-2xl">📷</span>,
    },
    {
      key: "CamaraVibra",
      title: "⚠️ Alerta: Vibración Detectada",
      description: `**Notamos que tu cámara vibra**, te recomendamos ajustar bien la cámara a la superficie, si esta sobre un poste, idealmente flechala o generá algun suporte para evitar esta vibración.

Recordá si es madera, puede existir dilatación de la misma.

En caso de persistir el problema, podría afectar la calidad del timelapse.`,
      icon: <span className="text-2xl">📷</span>,
    },
    {
      key: "CobroAtrasado1",
      title: "⚠️ IMPORTANTE: Cuenta Vencida",
      description: `Hola, tu cuenta de LisualPro esta vencida ⚠️

Por favor comunicate con el área de cobranzas para ponerte al día.`,
      icon: <span className="text-2xl">⚠️</span>,
      buttonText: "Contactar Cobranzas",
      buttonLink: "https://wa.link/0w6kt5",
    },
    {
      key: "CobroAtrasado2",
      title: "🚨 URGENTE: Cuenta Impaga",
      description: `Tu cuenta en LisualPro sigue impaga.

En caso de no ponerte al día en los próximos 10 dias, el servicio del software se verá interrumpido y la cámara únicamente capturará 1 foto por día.

🚨 Por favor hace clic en el botón debajo para que el contenido final no se vea afectado.`,
      icon: <span className="text-2xl">⚠️</span>,
      buttonText: "Regularizar Pago",
      buttonLink: "https://wa.link/0w6kt5",
    },
    {
      key: "CobroAtrasado3",
      title: "🚨 CRITICO: TU CÁMARA DEJARA DE FUNCIONAR",
      description: `Tu cuenta en LisualPro sigue impaga.

En caso de no ponerte al día en los próximos 3 dias, el servicio del software se verá interrumpido por completo, no podrás ver la cámara ni desde la app ni desde la web LisualPro.

🚨 Por favor hace clic en el botón debajo para que el contenido final no se vea afectado.`,
      icon: <span className="text-2xl">⚠️</span>,
      buttonText: "Regularizar Pago Urgente",
      buttonLink: "https://wa.link/0w6kt5",
    },
    {
      key: "UltimoAviso",
      title: "🚨 AVISO: PROCEMOS A DESACTIVAR TU CUENTA",
      description: `No recibimos novedades de tu parte en las últimas comunicaciones, queriamos notificarte que procedemos a eliminar tu cuenta y el material generado hasta el momento en caso de no recibir tu respuesta.

      Tu cuenta sera eliminada en 24 horas.
      
Gracias.`,
      icon: <span className="text-2xl">⚠️</span>,
      buttonText: "Regularizar Pago Urgente",
      buttonLink: "https://wa.link/0w6kt5",
    },
    {
      key: "LenteSucio",
      title: "Lente Sucio",
      description: `Notamos que el lente de tu cámara esta sucio, por favor acercate a la misma y limpiala para lograr que el timelapse quede perfecto!`,
      icon: <span className="text-2xl">📷</span>,
    },
    {
      key: "RecomendacionCamara",
      title: "Recomendación de Cámara",
      description: `Te recomendamos desplazar tu cámara de lugar, detectamos que en la ubicación que se encuentra actualmente ya no es muy util, y capaz existe alguna otra posicion en el espacio del proyecto para lograr un contenido más llamativo 🚀.`,
      icon: <span className="text-2xl">📷</span>,
    },
    {
      key: "RecordatorioReunion",
      title: "Recordatorio de Reunión",
      description: `Hola 😀, te queria invitar a una reunión sincronica para resolver cualquier duda técnica, la misma sera a las 15:00 hs (GMT - 3) (se repite todos los jueves al mismo horario)

Acordate de agendartela en tu Calendar y ponerte alarmas en tu celu, asi no te olvidas!

Para participar, hace clic en el botón debajo.`,
      buttonText: "Agendar Reunión",
      buttonLink: "https://forms.gle/v9RFKiNxAxneTnpu8",
      icon: <span className="text-2xl">🗓️</span>,
    },
    {
      key: "SolicitarFeedback",
      title: "Solicitud de Feedback",
      description: `Tu feedback nos ayuda a mejorar, queremos que LisualPro tengá todo lo que necesitas para que ayudarte en tu día a día.

Dejanos tu opinión debajo y si tu idea es elegida, sera la proximá funcion en desarrollarse. 👇`,
      buttonText: "Dejar Feedback",
      buttonLink: "https://forms.gle/wqq6BgSHXXzCcGBm8",
      icon: <span className="text-2xl">📝</span>,
    },
    {
      key: "Bienvenida",
      title: "Bienvenido a LisualPro!",
      description: `Bienvenido a LisualPro, en este espacio te estaré notificando cualquier tipo de alerta que tenga tu cámara para que el resultado final quede excelente. 
      
      Una vez que confirmes la resolución de cada notificación, las mismas no volveran a aparecer 👇`,
      icon: <span className="text-2xl">👋</span>,
    },
    {
      key: "TodaviaNoInstalaron",
      title: "¿Ya pudieron instalar la cámara?",
      description: `Notamos que aun no han instalado la cámara en la obra, queriamos asegurarnos de hacer este seguimiento del proyecto 😀.`,
      icon: <span className="text-2xl">📸</span>,
    },
  ];

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen bg-gray-900">
        <div className="text-xl text-white">Loading...</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-900 flex flex-col">
      <ToastContainer />
      <div className="bg-sky-100 p-4 shadow-lg">
        <div className="max-w-2xl mx-auto flex justify-between items-center">
          <img src={logo} alt="Logo" className="h-12 object-contain" />
          {identifier && (
            <a
              href={`https://pro.lisual.com/dashboard/${identifier}`}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center gap-2 bg-blue-600 hover:bg-blue-700 text-white font-semibold px-4 py-2 rounded-lg transition-colors"
            >
              <Layout className="w-4 h-4" />
              Ir al Dashboard
            </a>
          )}
        </div>
      </div>

      <div className="bg-yellow-500 py-3 px-4">
        <div className="max-w-2xl mx-auto flex items-center gap-2">
          <AlertTriangle className="w-6 h-6 text-black" />
          <p className="font-semibold text-black">
            {userName ? `Hola ${userName}, tienes` : "Tienes"} notificaciones
            importantes que requieren tu atención
          </p>
        </div>
      </div>
      {projectName && (
        <div className="bg-gray-800 py-3 px-4 border-b border-gray-700">
          <div className="max-w-2xl mx-auto font-9xl">
            <p className="text-white text-center">
              <span className="text-sky-400 font-bold text-4xl text-center">{projectName}</span>
            </p>
          </div>
        </div>
      )}

      <div className="flex-1 min-h-0 overflow-y-auto">
        <div className="max-w-2xl mx-auto px-4 py-8">
          <div className="space-y-6">
            {notifications.map(
              ({ key, title, description, icon, buttonText, buttonLink }) =>
                notificationStates[key] && (
                  <div
                    key={key}
                    className="border-2 border-red-500 bg-gray-800 rounded-lg p-6 flex flex-col gap-4 hover:border-opacity-80 transition-all duration-300"
                  >
                    <div className="flex items-start gap-4">
                      <div className="p-3 bg-gray-700/50 rounded-xl shrink-0 shadow-lg backdrop-blur-sm border border-gray-600/30">
                        {icon}
                      </div>
                      <div className="flex-1">
                        <h3 className="font-bold text-xl text-white mb-4">
                          {title}
                        </h3>
                        <div className="text-gray-300 mb-6 leading-relaxed">
                          {formatDescription(description)}
                        </div>
                        <div className="flex flex-wrap gap-4 items-center">
                          {buttonText && buttonLink && (
                            <a
                              href={buttonLink}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="inline-flex items-center gap-2 bg-blue-500 hover:bg-blue-600 text-white font-semibold px-8 py-3 rounded-lg transition-colors"
                            >
                              {buttonText}
                              <ExternalLink className="w-4 h-4" />
                            </a>
                          )}
                          <button
                            onClick={() => handleDismissNotification(key)}
                            className="bg-green-600 hover:bg-green-700 text-white px-6 py-2 rounded-lg flex items-center gap-2 transition-colors duration-200"
                          >
                            <CheckCircle className="w-5 h-5" />
                            Recibido
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )
            )}

            {!Object.values(notificationStates).some((value) => value) && (
              <div className="border-2 border-green-500 bg-gray-800 rounded-lg p-6 flex items-center gap-3">
                <CheckCircle className="w-8 h-8 text-green-500" />
                <p className="text-green-400 font-semibold">
                  {userName ? `${userName}, no tienes` : "No hay"} alertas
                  activas en este momento
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="h-16"></div>
      </div>
    </div>
  );
};

export default NotificationPage;
