import React, { useState, useEffect, useCallback } from 'react';
import { InstagramIcon, Share2, ExternalLink, Star, Construction, ChevronLeft, ChevronRight, MapPin, Check, Copy } from 'lucide-react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from './firebase-config';

const CommunityPreview = () => {
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [isTransitioning, setIsTransitioning] = useState(false);
    const [isPaused, setIsPaused] = useState(false);
    const [showShareSuccess, setShowShareSuccess] = useState(false);
    const handleShare = async (project) => {
        if (!project.instagram) return;

        const shareData = {
            title: `${project.Name} en Instagram`,
            text: `Seguí el avance de la obra ${project.Name} en Instagram 📸\nProyecto destacado por Lisual.`,
            url: project.instagram
        };

        try {
            if (navigator.share) {
                // Mobile sharing
                await navigator.share(shareData);
            } else {
                // Desktop fallback - copy to clipboard
                await navigator.clipboard.writeText(`${shareData.text}\n${shareData.url}`);
                setShowShareSuccess(true);
                setTimeout(() => setShowShareSuccess(false), 2000);
            }
        } catch (error) {
            console.error('Error sharing:', error);
        }
    };


    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "usuarios"));
                const projectData = querySnapshot.docs
                    .map(doc => ({
                        id: doc.id,
                        ...doc.data(),
                        displayOrder: doc.data().displayOrder || 0
                    }))
                    .filter(project => project.visible !== false) // Only show visible projects
                    .sort((a, b) => a.displayOrder - b.displayOrder);
                setProjects(projectData);
            } catch (err) {
                console.error('Error fetching projects:', err);
                setError('Error al cargar los proyectos');
            } finally {
                setLoading(false);
            }
        };

        fetchProjects();
    }, []);

    const featuredProjects = projects.filter(project => project.featured);
    const regularProjects = projects.filter(project => !project.featured);

    const getLivecamUrl = (feedID) => {
        return `//www.teleport.io/api/v2/player?feedid=${feedID}&playmode=liveimageloop&imageplayspeed=1fps&playframeskipinterval=day&playframecount=600`;
    };

    const nextSlide = useCallback(() => {
        if (!isTransitioning && !isPaused) {
            setIsTransitioning(true);
            setCurrentSlide((prev) => (prev + 1) % featuredProjects.length);
            setTimeout(() => setIsTransitioning(false), 500);
        }
    }, [isTransitioning, isPaused, featuredProjects.length]);

    const prevSlide = () => {
        if (!isTransitioning) {
            setIsTransitioning(true);
            setCurrentSlide((prev) => (prev - 1 + featuredProjects.length) % featuredProjects.length);
            setTimeout(() => setIsTransitioning(false), 500);
        }
    };

    useEffect(() => {
        if (featuredProjects.length > 1 && !isPaused) {
            const interval = setInterval(nextSlide, 6000); // Changed to 6 seconds
            return () => clearInterval(interval);
        }
    }, [featuredProjects.length, currentSlide, isTransitioning, isPaused, nextSlide]);

    if (loading) {
        return (
            <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-gray-50 to-blue-50">
                <div className="text-center">
                    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500 mx-auto mb-4"></div>
                    <p className="text-gray-600">Cargando proyectos...</p>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-gray-50 to-blue-50">
                <div className="text-center text-red-600">
                    <p>{error}</p>
                    <button
                        onClick={() => window.location.reload()}
                        className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-all"
                    >
                        Reintentar
                    </button>
                </div>
            </div>
        );
    }

    return (
        <div className="min-h-screen p-3 md:p-6 bg-gradient-to-br from-gray-50 to-blue-50">
            {/* Header */}
            <div className="max-w-7xl mx-auto text-center mb-6 md:mb-12">
                <h1 className="text-3xl md:text-4xl font-bold text-gray-900 mb-3 md:mb-4">
                    Comunidad Lisual
                </h1>
                <p className="text-lg md:text-xl text-gray-600 max-w-2xl mx-auto px-4">
                    Explorá los proyectos más destacados de nuestra comunidad
                </p>
            </div>

            {/* Featured Projects Carousel */}
            {featuredProjects.length > 0 && (
                <div className="max-w-7xl mx-auto">
                    <h2 className="text-2xl md:text-3xl font-bold text-gray-900 mb-4 md:mb-6 px-4">
                        Proyectos Destacados
                    </h2>

                    {/* Carousel Container */}
                    <div className="relative">
                        {/* Navigation Arrows - Hidden on mobile, shown on desktop */}
                        {featuredProjects.length > 1 && (
                            <>
                                <button
                                    onClick={prevSlide}
                                    className="hidden md:flex absolute -left-10 top-1/2 -translate-y-1/2 bg-white hover:bg-gray-50 text-gray-800 p-4 rounded-full shadow-lg transition-all hover:scale-110 z-10"
                                    disabled={isTransitioning}
                                >
                                    <ChevronLeft className="h-6 w-6" />
                                </button>
                                <button
                                    onClick={nextSlide}
                                    className="hidden md:flex absolute -right-10 top-1/2 -translate-y-1/2 bg-white hover:bg-gray-50 text-gray-800 p-4 rounded-full shadow-lg transition-all hover:scale-110 z-10"
                                    disabled={isTransitioning}
                                >
                                    <ChevronRight className="h-6 w-6" />
                                </button>
                            </>
                        )}

                        {/* Main Content Card */}
                        <div
                            className="bg-white rounded-xl md:rounded-2xl shadow-xl overflow-hidden mx-3 md:mx-0"
                            onMouseEnter={() => setIsPaused(true)}
                            onMouseLeave={() => setIsPaused(false)}
                        >
                            <div
                                className="transition-transform duration-500 ease-in-out"
                                style={{ transform: `translateX(-${currentSlide * 100}%)` }}
                            >
                                <div className="flex">
                                    {featuredProjects.map((project) => (
                                        <div key={project.id} className="w-full flex-shrink-0">
                                            <div className="flex flex-col">
                                                {/* Livecam Section */}
                                                <div className="relative">
                                                    {/* Iframe Container - Adjust height for mobile */}
                                                    <div className="relative h-[280px] md:h-[480px] bg-gray-100">
                                                        {project.feedID ? (
                                                            <iframe
                                                                src={getLivecamUrl(project.feedID)}
                                                                title={`Livecam - ${project.Name}`}
                                                                className="w-full h-full border-0"
                                                            />
                                                        ) : (
                                                            <div className="absolute inset-0 flex items-center justify-center">
                                                                <Construction className="h-12 w-12 md:h-16 md:w-16 text-gray-400" />
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                {/* Project Info and Controls - Stack on mobile */}
                                                <div className="p-4 md:p-6 flex flex-col md:flex-row md:justify-between md:items-start gap-4">
                                                    {/* Left side - Project info */}
                                                    <div className="space-y-3">
                                                        <div className="flex items-center gap-2 text-gray-600">
                                                            <Star className="h-5 w-5 text-yellow-500 fill-yellow-500" />
                                                            <span>Proyecto Destacado</span>
                                                        </div>
                                                        <div className="flex items-center gap-4">
                                                            {project.logo && (
                                                                <img
                                                                    src={project.logo}
                                                                    alt={`${project.Name} logo`}
                                                                    className="h-12 w-12 object-contain rounded-lg"
                                                                />
                                                            )}
                                                            <h3 className="text-xl md:text-2xl font-bold text-gray-900">{project.Name}</h3>
                                                        </div>
                                                        <div className="flex items-center gap-2 text-gray-600">
                                                            <MapPin className="h-4 w-4" />
                                                            <p className="text-sm md:text-base">{project.address}</p>
                                                        </div>
                                                    </div>

                                                    {/* Right side - Action buttons */}
                                                    <div className="flex flex-col md:flex-row gap-3 w-full md:w-auto">
                                                        {project.instagram && (
                                                            <a
                                                                href={project.instagram}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                className="flex items-center justify-center gap-2 text-pink-500 hover:text-pink-600 transition-colors bg-pink-50 px-4 py-2 rounded-lg hover:bg-pink-100"
                                                            >
                                                                <InstagramIcon className="h-5 w-5" />
                                                                <span>Ver Instagram</span>
                                                            </a>
                                                        )}
                                                        <button
                                                            onClick={() => handleShare(project)}
                                                            className="relative flex items-center justify-center gap-2 text-blue-500 hover:text-blue-600 transition-colors bg-blue-50 px-4 py-2 rounded-lg hover:bg-blue-100 group"
                                                        >
                                                            {showShareSuccess ? (
                                                                <>
                                                                    <Check className="h-5 w-5" />
                                                                    <span>¡Copiado!</span>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {navigator.share ? (
                                                                        <>
                                                                            <Share2 className="h-5 w-5" />
                                                                            <span>Compartir</span>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <Copy className="h-5 w-5" />
                                                                            <span>Copiar link</span>
                                                                        </>
                                                                    )}
                                                                </>
                                                            )}

                                                            {/* Tooltip for desktop */}
                                                            {!navigator.share && !showShareSuccess && (
                                                                <div className="absolute -top-10 left-1/2 -translate-x-1/2 bg-gray-800 text-white text-xs py-1 px-2 rounded opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap">
                                                                    Copiar link al portapapeles
                                                                </div>
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>

                                                {/* Slide Indicators */}
                                                {featuredProjects.length > 1 && (
                                                    <div className="flex justify-center gap-2 pb-4">
                                                        {featuredProjects.map((_, index) => (
                                                            <button
                                                                key={index}
                                                                onClick={() => {
                                                                    if (!isTransitioning) {
                                                                        setIsTransitioning(true);
                                                                        setCurrentSlide(index);
                                                                        setTimeout(() => setIsTransitioning(false), 500);
                                                                    }
                                                                }}
                                                                className={`h-2 rounded-full transition-all ${currentSlide === index
                                                                    ? 'bg-blue-500 w-8'
                                                                    : 'bg-gray-300 w-2 hover:bg-gray-400'
                                                                    }`}
                                                            />
                                                        ))}
                                                    </div>
                                                )}
                                            </div>

                                        </div>
                                    ))}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className="flex flex-col items-center px-4 py-6 border-t border-gray-100">
                <p className='text-center font-medium text-gray-600 mb-4'>
                    ¿Querés que tu obra sea destacada?
                </p>
                <a
                    href="https://wa.me/5491166644156?text=Hola!%20Quiero%20destacar%20mi%20proyecto%20en%20la%20comunidad%20Lisual"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="group inline-flex items-center gap-3 bg-[#25D366] hover:bg-[#20BD5A] text-white px-6 py-3 rounded-lg font-medium transition-all duration-300 transform hover:scale-105 shadow-lg hover:shadow-xl hover:shadow-emerald-200/50 w-full md:w-auto justify-center relative overflow-hidden max-w-md">
                    <span className="absolute inset-0 bg-white/10 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                    <span className="transition-transform duration-300 group-hover:scale-110">
                        <svg
                            viewBox="0 0 24 24"
                            className="h-5 w-5 fill-current"
                        >
                            <path d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413z" />
                        </svg>
                    </span>
                    <span className="relative">Notificanos por WhatsApp</span>
                </a>
            </div>

            {/* Regular Projects Grid - Modified to include logos */}
            <div className="mt-6 md:mt-6 max-w-7xl mx-auto">
                <h2 className="text-2xl md:text-3xl font-semibold text-gray-900 mb-6 px-4">
                    {featuredProjects.length > 0 ? 'Conocé otros empresas que implementan nuestra solución:' : 'Proyectos'}
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6 px-3 md:px-0">
                    {regularProjects.map((project, index) => (
                        <div
                            key={project.id}
                            className="bg-white p-4 md:p-6 rounded-lg shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1"
                            style={{
                                animationDelay: `${index * 100}ms`,
                                opacity: 0,
                                animation: 'fade-in-up 0.5s ease forwards'
                            }}
                        >
                            <div className="space-y-4">
                                {/* Logo and Name Container */}
                                <div className="flex items-center gap-4">
                                    <div className="flex-shrink-0 w-12 h-12 bg-gray-50 rounded-lg overflow-hidden">
                                        {project.logo ? (
                                            <img
                                                src={project.logo}
                                                alt={`${project.Name} logo`}
                                                className="w-full h-full object-contain"
                                            />
                                        ) : (
                                            <div className="w-full h-full flex items-center justify-center bg-gray-100">
                                                <Construction className="h-6 w-6 text-gray-400" />
                                            </div>
                                        )}
                                    </div>
                                    <h3 className="text-lg md:text-xl font-semibold text-gray-800 flex-grow">{project.Name}</h3>
                                </div>

                                {/* Address */}
                                <div className="flex items-center gap-2 text-gray-600">
                                    <MapPin className="h-4 w-4 flex-shrink-0" />
                                    <p className="text-sm">{project.address}</p>
                                </div>

                                {/* Instagram Link */}
                                {project.instagram && (
                                    <div className="pt-2">
                                        <a
                                            href={project.instagram}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="inline-flex items-center gap-2 text-pink-500 hover:text-pink-600 transition-colors bg-pink-50 px-3 py-2 rounded-lg hover:bg-pink-100 w-full justify-center"
                                        >
                                            <InstagramIcon className="h-4 w-4" />
                                            <span className="text-sm font-medium">Seguir Proyecto</span>
                                            <ExternalLink className="h-4 w-4" />
                                        </a>
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="mt-6 md:mt-8 px-3 md:px-0">
                <div className="bg-white rounded-xl shadow-lg p-4 md:p-6 max-w-2xl mx-auto">
                    <div className="text-center space-y-3 md:space-y-4">
                        <h3 className="text-lg md:text-xl font-semibold text-gray-900">
                            ¿Querés que tu proyecto NO aparezca en la lista?
                        </h3>
                        <p className="text-sm md:text-base text-gray-600">
                            Escribinos por WhatsApp y lo removeremos de la lista.
                        </p>
                        <a
                            href="https://wa.me/5491166644156?text=Hola!%20Quiero%20destacar%20mi%20proyecto%20en%20la%20comunidad%20Lisual"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="inline-flex items-center gap-3 bg-[#25D366] hover:bg-[#20BD5A] text-white px-4 md:px-6 py-3 rounded-lg font-medium transition-all transform hover:scale-105 shadow-lg hover:shadow-xl w-full md:w-auto justify-center"
                        >
                            <svg
                                viewBox="0 0 24 24"
                                className="h-5 w-5 fill-current"
                            >
                                <path d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413z" />
                            </svg>
                            <span>Contactanos por WhatsApp</span>
                        </a>
                        <p className="text-xs md:text-sm text-gray-500">
                            Te responderemos a la brevedad.

                        </p>
                    </div>
                </div>
            </div>

            <style jsx>{`
                @keyframes fade-in {
                    from { opacity: 0; }
                    to { opacity: 1; }
                }

                @keyframes fade-in-up {
                    from {
                        opacity: 0;
                        transform: translateY(20px);
                    }
                    to {
                        opacity: 1;
                        transform: translateY(0);
                    }
                }

                .animate-fade-in {
                    animation: fade-in 0.5s ease forwards;
                }

                /* Add touch-action for better mobile handling */
                @media (max-width: 768px) {
                    .carousel-container {
                        touch-action: pan-y pinch-zoom;
                    }
                }
            `}</style>
        </div>
    );
};

export default CommunityPreview;